import React from 'react'
import styles from './style/type3.module.scss'
import dynamic from "next/dynamic";

const ButtonType1 = dynamic(()=>import('@/components/Button/type1'))
const ButtonType2 = dynamic(()=>import('@/components/Button/type2'))

export default function Type3({ list, isMobile }: any) {
  return (
    <div className={styles.type3}>
      {list.map((item: any, index: number) => (
        <div key={index} className={styles.item}>
          <a
            href={item?.couponPath ? item?.couponPath : ''}
            className={styles.item_left}
          >
            <img src={item.storeLogo ? item.storeLogo : ''} alt="" loading={"lazy"}/>
          </a>
          <div className={styles.item_center}>
            <a
              href={item?.couponPath ? item?.couponPath : ''}
              className={styles.name}
            >
              {item.storeName ? item.storeName : ''}
            </a>
            <div
              className={styles.title}
              onClick={() => {
                const href = window.location.href.split('?')[0]
                window.open(`${href}${item?.couponPath}`, '_blank')
                window.location.href = item?.link
              }}
            >
              {item.title ? item.title : ''}
            </div>
            <div className={styles.time}>{item.exp ? item.exp : ''}</div>
          </div>
          {
              !isMobile &&
          <div className={styles.item_right}>
            {item?.discountCode && item?.discountCode !== '' ? (
              <ButtonType1
                link={item?.link ?? ''}
                couponLink={item?.couponPath ?? ''}
                code={item?.discountCode ?? ''}
                text={item?.actionText ?? 'Get Code'}
                id={null}
              ></ButtonType1>
            ) : (
              <ButtonType2
                link={item?.link ?? ''}
                couponLink={item?.couponPath ?? ''}
                text={item?.actionText ?? 'Get Deal'}
                id={null}
              ></ButtonType2>
            )}
          </div>
          }
          <div
            className={styles.item_code}
            style={{
              background:
                item?.discountCode && item?.discountCode !== ''
                  ? '#a243b5'
                  : '#6982bb ',
            }}
          >
            {item?.discountCode && item?.discountCode !== '' ? 'Code' : 'Deal'}
          </div>
        </div>
      ))}
    </div>
  )
}
